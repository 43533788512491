import type { GatsbyBrowser } from 'gatsby';

// eslint-disable-next-line @typescript-eslint/no-misused-promises
const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  try {
    const preloadChartScript = document.getElementById('preload-chart');

    if (!preloadChartScript) {
      const preloadChart = document.createElement('iframe');
      preloadChart.style.display = 'none';
      preloadChart.src = 'https://lucid.app/documents/preloadChart';
      preloadChart.dataset.osano = 'ESSENTIAL';

      const loadPreloadScripts = () => {
        document.getElementsByTagName('body')[0].appendChild(preloadChart);
        window.removeEventListener('scroll', loadPreloadScripts);
      };
      window.addEventListener('scroll', loadPreloadScripts);
    }
  } catch (error) {
    console.error(`onClientEntry: ${error}`);
  }
};

export { onClientEntry };
